// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class TenderBiddersService {

    index() {
        return axios.get(API_URL + 'bidders', { headers: authHeader() })
    }
    approvable_index(user) {
        return axios.get(API_URL + 'applications-for-approver/' + user.id, { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'bidders/'+id, { headers: authHeader() })
    }
    store(bidder) {
        return axios.post(API_URL + 'bidders', bidder, { headers: authHeader() })
    }
    update(bidder) {
        return axios.put(API_URL + 'bidders/' + bidder.id, bidder, { headers: authHeader() })
    }
    delete(bidder) {
        return axios.delete(API_URL + 'bidders/' + bidder.id, { headers: authHeader() })
    }
    getAllTenderBidders() {
        return axios.get(API_URL + 'get-all-bidders', { headers: authHeader() })
    }
    getAllTenderBiddersByPercentage() {
        return axios.get(API_URL + 'get-all-bidders-by-percentage', { headers: authHeader() })
    }
    getAllBiddersByMonth() {
        return axios.get(API_URL + 'get-bidders-by-month', { headers: authHeader() })
    }
}

export default new TenderBiddersService()
