<template>
  <div>
    <v-stepper class="mt-n4" v-model="e1">
      <v-progress-linear
        color="primary"
        buffer-value="100"
        height="5"
        striped
        :value="(e1 / 7) * 100"
        class="mt-"
      ></v-progress-linear>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="e1 > 1">
          Need
          <small>Need Identification</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="e1 > 2">
          Pre-approval
          <small>Obtain Pre-approval</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="e1 > 3">
          Supplier Selection
          <small>Pre-qualified suppliers</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="e1 > 4">
          Approval
          <small>by authority</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="5" :complete="e1 > 5">
          Contract
          <small>Initiation</small>
        </v-stepper-step>

        <v-stepper-step step="6" :complete="e1 > 6">
          Purchase
          <small>Requisition</small>
        </v-stepper-step>

        <v-stepper-step step="7" :complete="e1 > 7">
          Purchase
          <small>Order</small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-divider class="my-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: "MainStepper",
  props: ["e1"],
  components: {},
  data: () => ({
    selection: 1,
    timer: "",
  }),
  computed: {},
  methods: {},
};
</script>
