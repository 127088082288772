<template>
  <div>
    <v-row v-if="!user.abilities.includes('view-dashboard')">
      <v-col xs="12" md="12">
        <v-alert
          outlined
          type="warning"
          prominent
          border="top"
          icon="mdi-alert"
          class="mx-auto"
          transition="scale-transition"
        >
          You have no sufficient previlege to access this page or resource,
          please contact the system administrator.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-row class="mt-n3">
        <v-col xs="12" md="12">
          <div class="mx-2">
            <MainStepper e1="0" />
          </div>
          <v-layout wrap="wrap">
            <v-flex v-for="(stat, s) in stats" :key="s" class="mx-2">
              <v-card align="center" link :to="stat.href">
                <v-toolbar>
                  <v-icon large :color="stat.color">
                    {{ stat.icon }}
                  </v-icon>
                  <v-spacer vertical="true"></v-spacer>
                  <v-toolbar-title>
                    {{ stat.number }}
                    <div class="caption font-weight-light">
                      {{ stat.label }}
                    </div>
                  </v-toolbar-title>
                </v-toolbar>
                <v-progress-linear
                  :color="stat.color"
                  value="100"
                ></v-progress-linear>
              </v-card>
            </v-flex>
          </v-layout>
        </v-col>
        <v-divider class="my-1"></v-divider>
        <v-col cols="12" md="6" sm="6">
          <v-card class="ml-2" v-if="bar_chart_is_ready">
            <v-card-text>
              <GChart
                :settings="{ packages: ['bar'] }"
                :data="published"
                :options="publishedOptions"
                :createChart="(el, google) => new google.charts.Bar(el)"
              />
            </v-card-text>
          </v-card>

          <v-card class="ml-2" v-else>
            <v-card-text>
              <v-row align="center" justify="center" class="text-center">
                <v-col>
                  <v-progress-circular
                    indeterminate
                    color="error"
                    class="my-3"
                  ></v-progress-circular>
                  <p caption>Published Tenders Loading... Please wait</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <v-card class="mr-2" v-if="pie_chart_is_ready">
            <v-card-text>
              <GChart
                type="PieChart"
                loading="true"
                :data="tenders_by_category"
                :options="poChartOptions2"
              />
            </v-card-text>
          </v-card>

          <v-card class="ml-2" v-else>
            <v-card-text>
              <v-row align="center" justify="center" class="text-center">
                <v-col>
                  <v-progress-circular
                    indeterminate
                    color="error"
                    class="my-3"
                  ></v-progress-circular>
                  <p caption>Tender Categories Loading... Please wait</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" sm="12">
          <v-card class="ml-2" v-if="line_chart_is_ready">
            <v-card-text>
              <GChart
                :settings="{ packages: ['line'] }"
                :data="bids"
                :options="bidsOptions"
                :createChart="(el, google) => new google.charts.Line(el)"
                :events="chartEvents"
              />
            </v-card-text>
          </v-card>

          <v-card class="ml-2" v-else>
            <v-card-text>
              <v-row align="center" justify="center" class="text-center">
                <v-col>
                  <v-progress-circular
                    indeterminate
                    color="error"
                    class="my-3"
                  ></v-progress-circular>
                  <p caption>Bids Received Loading... Please wait</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!--
        <v-col cols="12" md="6" sm="12">
          <v-card>
            <v-card-text>
              <GChart
                :settings="{ packages: ['bar'] }"
                :data="chartData"
                :options="usersChartOptions"
                :createChart="(el, google) => new google.charts.Bar(el)"
                @ready="onChartReady"
              />
            </v-card-text>
          </v-card>
        </v-col>
        -->

        <v-col cols="12" md="6" sm="6">
          <v-card class="mr-2" v-if="bidders_pie_chart_is_ready">
            <v-card-text>
              <template>
                <GChart
                  type="PieChart"
                  loading="true"
                  :data="bidders_by_percentage"
                  :options="bidsPieChartOptions"
                />
              </template>
            </v-card-text>
          </v-card>

          <v-card class="ml-2" v-else>
            <v-card-text>
              <v-row align="center" justify="center" class="text-center">
                <v-col>
                  <v-progress-circular
                    indeterminate
                    color="error"
                    class="my-3"
                  ></v-progress-circular>
                  <p caption>Bids Percentage Loading... Please wait</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!--
        <v-col cols="12" md="6" sm="12">
            <v-card class="ml-2">
                <v-card-text>
                      <v-date-picker
                          v-model="picker"
                          elevation="15"
                          color="red lighten-1"
                          full-width
                          :landscape="$vuetify.breakpoint.smAndUp"
                          class="mt-3">
                      </v-date-picker>
                </v-card-text>
            </v-card>
        </v-col>
        -->
      </v-row>
    </v-row>
  </div>
</template>

<script>
import TenderService from "../../services/tender.service";
import TenderBiddersService from "../../services/tender_bidders.service";
import UserService from "../../services/user.service";
import MainStepper from "../../components/MainStepper.vue";

export default {
  components: {
    MainStepper,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.getAllTenderBidders();
    this.getQuotationsCount();
    this.getTendersCount();
    this.getClosedTendersCount();
  },

  mounted() {
    this.getPublished();
    this.getAllTenderBiddersByPercentage();
    this.getAllTendersByCategory();
    this.getAllBiddersByMonth();
  },

  data() {
    return {
      e1: 0,
      dialog: true,
      pageReady: false,
      bar_chart_is_ready: false,
      pie_chart_is_ready: false,
      bidders_pie_chart_is_ready: false,
      line_chart_is_ready: false,
      column_chart_is_ready: false,
      breadcrumbs_items: [
        {
          disabled: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Dashboard",
        },
        {
          disabled: false,
          exact: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Need Identification",
        },
        {
          disabled: false,
          exact: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Pre-Approval",
        },
        {
          disabled: false,
          exact: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Supplier Selection",
        },
        {
          disabled: false,
          exact: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Contract",
        },
        {
          disabled: false,
          exact: true,
          href: "/dashboard",
          link: "/dashboard",
          text: "Purchase Requisition",
        },
      ],
      stats: [
        {
          number: "0",
          label: "Published Tenders",
          loading: false,
          description: "10% since last week",
          color: "error",
          icon: "mdi-file-document-edit",
          href: "tenders",
        },
        {
          number: "0",
          label: "Received Bids",
          loading: false,
          description: "15% since last week",
          color: "green",
          icon: "mdi-account-plus",
          href: "bids",
        },
        {
          number: "0",
          label: "Quotations",
          loading: false,
          description: "40% of monthly total expected",
          color: "blue",
          icon: "mdi-comment-quote",
          href: "quotations",
        },
        {
          number: "0",
          label: "Closed Tenders/History",
          loading: false,
          description: "5% of total this month",
          color: "grey",
          icon: "mdi-history",
          href: "#",
        },
      ],
      // Array will be automatically processed with visualization.arrayToDataTable function

      poChartOptions: {
        title: "Purchase Orders",
        is3D: false,
        pieHole: 0.4,
        legend: {
          position: "bottom",
        },
      },
      poChartOptions2: {
        title: "Published Categories",
        subtitle: "Current published tenders categories",
        is3D: false,
        pieHole: 0.4,
        legend: {
          position: "bottom",
        },
      },
      bids: [
        ["Month", "Bids"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],
      bidsOptions: {
        chart: {
          title: "Received Bids",
          subtitle: "All Received Bids and Applications",
          is3D: false,
          pieHole: 0.4,
        },
      },
      published: [
        ["Month", "Tenders"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],

      publishedOptions: {
        chart: {
          title: "Published Tenders 2021",
          subtitle: "Current published tenders on marketplace",
          color: "#76A7FA",
          is3D: true,
          pieHole: 0.4,
        },
      },

      chartData: [
        ["Month", "Users"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],

      chartEvents: {
        ready: () => {
          // do something when chart is first drawn
        },
      },

      usersChartOptions: {
        chart: {
          title: "System Users",
          subtitle: "Bidders, Administrators, and Companies: 2021",
          color: "#97d1fa",
          is3D: false,
          pieHole: 0.4,
        },
      },

      tenders_by_category: [
        ["Tenders Category", "Percentage"],
        ["Open Tender", 0],
        ["Prequalification", 0],
        ["Restricted Tender", 0],
        ["Expression of Interest", 0],
        ["Direct Procurement", 0],
        ["Request for Quotation", 0],
        ["Single-stage and two-stage tender", 0],
      ],

      list_of_latest_tenders: [],
      bidders_by_percentage: [
        ["Bids Category", "Percentage"],
        ["Approved", 0],
        ["Pending", 0],
        ["Rejected", 0],
      ],
      monthly_users_by_role: [],

      suppliersChartData: [
        [
          "Month",
          "COMMISSIONING AND MAINTENANCE CLOUD SERVICES",
          "COMMISSIONING AND MAINTENANCE OF DATA CENTRE",
          "GOVERNMENT NETWORKS MAINTENANCE",
          "LOCAL AREA NETWORKS-TIER 1",
          "LOCAL AREA NETWORKS-TIER 2",
        ],
        ["Jan", 1000, 400, 200, 350, 250],
        ["Feb", 1170, 460, 250, 100, 150],
        ["Mar", 660, 1120, 300, 450, 100],
        ["Apr", 1030, 540, 350, 350, 200],
        ["May", 1000, 400, 200, 350, 250],
        ["Jun", 1170, 460, 250, 100, 150],
        ["Jul", 660, 1120, 300, 450, 100],
        ["Aug", 1030, 540, 350, 350, 200],
        ["Sep", 660, 1120, 300, 450, 100],
        ["Oct", 1030, 540, 350, 350, 200],
        ["Nov", 1170, 460, 250, 100, 150],
        ["Dec", 660, 1120, 300, 450, 100],
      ],

      suppliersChartOptions: {
        chart: {
          title: "Number of Suppliers By Supplier Category",
          subtitle: "Suppliers: 2021",
        },
        bars: "horizontal",
        hAxis: { format: "decimal" },
        height: 1000,
        chartArea: {
          width: "100%",
          height: "100%",
        },
        colors: ["#77ba99", "#ef9e44", "#97d1fa", "#a45f6e", "#d14f57"],
      },

      picker: new Date().toISOString().substr(0, 10),

      bidsPieChartData: [
        ["Bids Category", "Percentage"],
        ["Approved", 0],
        ["Pending", 0],
        ["Rejected", 0],
      ],

      bidsPieChartOptions: {
        title: "Bids Statistics",
        subtitle: "Statistics on Received Bids: 2021",
        is3D: false,
        legend: {
          position: "bottom",
        },
      },

      headers: [
        {
          text: "Tender No",
          align: "start",
          filterable: true,
          value: "tender_no",
        },
        {
          text: "Tender Details",
          value: "title",
        },
        {
          text: "Category",
          value: "category.title",
        },
        {
          text: "Closing Date",
          value: "closing_date",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Clarifications",
          value: "clarifications_count",
        },
        {
          text: "Bidders Count",
          value: "bidders_count",
        },
      ],

      items: [
        {
          id: 1,
          name: "Dakota Rice",
          country: "Niger",
          city: "Oud-Tunrhout",
          salary: "$35,738",
        },
        {
          id: 2,
          name: "Minerva Hooper",
          country: "Curaçao",
          city: "Sinaai-Waas",
          salary: "$23,738",
        },
        {
          id: 3,
          name: "Sage Rodriguez",
          country: "Netherlands",
          city: "Overland Park",
          salary: "$56,142",
        },
        {
          id: 4,
          name: "Philip Chanley",
          country: "Korea, South",
          city: "Gloucester",
          salary: "$38,735",
        },
        {
          id: 5,
          name: "Doris Greene",
          country: "Malawi",
          city: "Feldkirchen in Kārnten",
          salary: "$63,542",
        },
      ],
    };
  },

  methods: {
    onChartReady() {},
    getCompanyName(company) {
      return company.company_name;
    },

    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },

    getAllTendersByCategory() {
      return TenderService.getAllTendersByCategory().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            console.log(this.tenders_by_category);
            this.tenders_by_category = Object.entries(obj);
            this.pie_chart_is_ready = true;
          } else {
            this.tenders_by_category = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getPublished() {
      return TenderService.getPublished().then(
        (response) => {
          if (response.status == 200) {
            this.published = Object.entries(response.data.data);
            this.bar_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllTenders() {
      return TenderService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.list_of_latest_tenders = response.data.data;
            console.log(this.list_of_latest_tenders);
          } else {
            this.list_of_latest_tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },

    getTendersCount() {
      return TenderService.getTendersCount().then(
        (response) => {
          if (response.status == 200) {
            this.stats[0].number = response.data.data.toString();
            console.log("Tenders: " + response.data.data);
            this.stats[0].loading = true;
          } else {
            this.stats[0].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getClosedTendersCount() {
      return TenderService.getClosedTendersCount().then(
        (response) => {
          if (response.status == 200) {
            this.stats[3].number = response.data.data.toString();
            console.log("Closed Tenders: " + response.data.data);
            this.stats[3].loading = true;
          } else {
            this.stats[3].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.stats[3].number = [];
          console.log(error);
        }
      );
    },

    getQuotationsCount() {
      return TenderService.getAllQuotations().then(
        (response) => {
          if (response.status == 200) {
            this.stats[2].number = response.data.data.toString();
            this.stats[2].loading = true;
          } else {
            this.stats[2].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.stats[2].number = [];
          console.log(error);
        }
      );
    },

    getAllTenderBidders() {
      return TenderBiddersService.getAllTenderBidders().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.stats[1].number = response.data.data.toString();
            console.log("Bids: " + response.data.data);
            this.stats[1].loading = true;
          } else {
            this.stats[1].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllBiddersByMonth() {
      return TenderBiddersService.getAllBiddersByMonth().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.bids = Object.entries(response.data.data);
            this.line_chart_is_ready = true;
            console.log(this.bids);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.bids = [];
          console.log(error);
        }
      );
    },

    getAllTenderBiddersByPercentage() {
      return TenderBiddersService.getAllTenderBiddersByPercentage().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            this.bidders_by_percentage = Object.entries(obj);
            console.log(this.bidders_by_percentage);
            this.bidders_pie_chart_is_ready = true;
          } else {
            this.bidders_by_percentage = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllMonthlyUsersByRole() {
      return UserService.getAllMonthlyUsersByRole().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            this.chartData = Object.entries(obj);
            console.log(response.data.data);
            this.column_chart_is_ready = true;
          } else {
            this.bidders_by_percentage = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
  },
};
</script>
